import { PatientService } from '@/services/patient.service';
import { PrescriptionService } from '@/services/prescription.service';
import { LookupService } from '@/services/lookup.service';
export default {
  props: {
    rowData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      showDialog: false,
      patientData: {},
      allPrescriptions: [],
      prescriptionData: [],
      prescriptionToCancel: [],
      prescriptionToRefund: [],
      reasons: [],
      total: 0,
      alert: {
        show: false,
        message: ''
      },
      // Load -> initial loading of data
      // Processing -> Processing cancellation
      loading: false,
      processing: false,
      cancellationReason: '',
      cancellationNotes: ''
    };
  },
  computed: {
    filteredPrescriptionData() {
      const toFilterOut = ['COMPLETED', 'CANCELLED'];
      const arr = this.prescriptionData.filter(item => {
        if (toFilterOut.includes(item.prescriptionstatus)) {
          return false;
        } else {
          return true;
        }
      });
      return arr;
    },
    totalString() {
      return `$${this.total}`;
    }
  },
  watch: {
    async showDialog(show) {
      if (show) {
        this.alert.show = false;
        this.loading = true;
        try {
          this.buttonDisabled = true;
          const patientData = await PatientService.getPatient(this.rowData.id);
          this.patientData = patientData.data;
          const allPrescription = await PrescriptionService.getPrescription(this.rowData.id);
          this.allPrescriptions = allPrescription.data;
          const {
            data
          } = await PrescriptionService.getPrescriptionForCancellation(this.rowData.id);
          this.prescriptionData = data;

          // Additional properties needed
          for (const prescription of this.prescriptionData) {
            const sameItem = this.allPrescriptions.find(o => {
              return o.id == prescription.id;
            });
            this.total += prescription.refundamount;
            prescription.dosage = sameItem.dosage;
          }

          // Load cancellation reasons
          const reasons = await LookupService.getCancelReason();
          this.reasons = reasons.data.map(reason => {
            return {
              text: reason.name,
              value: reason.id
            };
          });
          if (this.filteredPrescriptionData.length == 0) {
            this.showAlert('There are no cancellable prescriptions available.');
          }
          this.buttonDisabled = false;
        } catch (err) {
          this.alert.show = true;
          this.alert.message = err;
          this.buttonDisabled = false;
        } finally {
          this.loading = false;
        }
      } else {
        this.$emit('refresh-prescriptions');
        this.prescriptionToCancel = [];
        this.prescriptionToRefund = [];
        this.cancellationReason = '';
        this.cancellationNotes = '';
      }
    }
  },
  methods: {
    showAlert(message) {
      this.alert.message = message;
      this.alert.show = true;
    },
    getPaymentString(date) {
      if (!date) {
        return '-';
      }
      if (date == '-') {
        return '-';
      }
      return new Date(date * 1000).toDateString();
    },
    getRefillString(med) {
      if (med.refills) {
        if (['SCHEDULED', 'WAITING', 'PROCESSING', 'MIGRATED', 'DRAFTED'].includes(med.prescriptionstatus)) {
          return `Refills: ${med.refillinput}`;
        }
        const refills = med.refills - 1 - (med.paidrefills == 0 ? 0 : med.paidrefills - 1);
        if (refills == 0 && med.refills - 1 == 0) {
          return 'One time purchase';
        } else {
          return `${refills}/${med.refills - 1} refills`;
        }
      } else {
        return '-';
      }
    },
    async cancelPrescriptions() {
      try {
        this.processing = true;
        // * Reorder IDs for progesterone
        for (const cancellation of this.prescriptionToCancel) {
          // Get item
          const prescription = this.prescriptionData.find(o => o.id == cancellation);
          if (prescription.medicinename.toLowerCase().includes('progesterone')) {
            // Check if estrogen is already in list
            const estrogen = this.prescriptionData.find(o => o.medicinename.toLowerCase().includes('estrogen'));
            console.log(estrogen.id);
            if (!this.prescriptionToCancel.includes(estrogen.id)) {
              this.showAlert('You cannot cancel progesterone without cancelling estrogen as well.');
              this.processing = false;
              return;
            } else {
              // Move estrogen ID to start of cancellation queue
              // Find index
              const estrogenIndex = this.prescriptionToCancel.findIndex(el => el == estrogen.id);
              this.prescriptionToCancel.splice(estrogenIndex, 1);
              this.prescriptionToCancel.unshift(estrogen.id);
            }
          }
        }
        for (const cancellation of this.prescriptionToCancel) {
          const body = {};
          if (this.cancellationReason) {
            body.cancellationreason = this.cancellationReason;
          }
          if (this.cancellationNotes) {
            body.cancellationnotes = this.cancellationNotes;
          }
          if (this.prescriptionToRefund.includes(cancellation)) {
            body.refund = true;
          }
          console.log(body);
          await PrescriptionService.cancelSinglePrescription(cancellation, body);
        }
        this.$emit('refresh-prescriptions');
        this.processing = false;
        this.closeDialog();
      } catch (err) {
        this.processing = false;
        this.showAlert(err);
      }
    },
    closeDialog() {
      this.$emit('reset-selected-row');
      this.showDialog = false;
    }
  }
};