var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("b-modal", {
    attrs: {
      "hide-header": "",
      "hide-footer": "",
      centered: "",
      "no-close-on-backdrop": "",
      "no-stacking": "",
      size: "xl"
    },
    model: {
      value: _vm.showDialog,
      callback: function ($$v) {
        _vm.showDialog = $$v;
      },
      expression: "showDialog"
    }
  }, [_c("b-alert", {
    attrs: {
      variant: "warning",
      show: _vm.alert.show
    }
  }, [_vm._v(" " + _vm._s(_vm.alert.message) + " ")]), _c("b-row", {
    staticClass: "mb-4",
    attrs: {
      "align-h": "between"
    }
  }, [_c("b-col", {
    attrs: {
      cols: "10"
    }
  }, [_c("img", {
    attrs: {
      src: require("@/assets/images/icon_logo_violet.svg"),
      alt: ""
    }
  })]), _c("b-col", {
    staticClass: "text-right"
  }, [_c("a", {
    staticClass: "tw-text-eclipse",
    attrs: {
      href: "javascript:;"
    },
    on: {
      click: function ($event) {
        _vm.showDialog = false;
      }
    }
  }, [_c("b-icon", {
    attrs: {
      icon: "x-circle",
      "font-scale": "1.5"
    }
  })], 1)])], 1), _c("b-row", {
    staticClass: "mt-4 pr-4"
  }, [_c("b-col", {
    attrs: {
      cols: "2"
    }
  }, [_c("p", {
    staticClass: "text-primary"
  }, [_vm._v(" Cancel Prescription ")])]), _c("b-col", {
    attrs: {
      cols: "10"
    }
  }, [_c("b-row", [_c("b-col", {
    attrs: {
      cols: "3"
    }
  }, [_c("span", {
    staticClass: "text-primary text-small"
  }, [_vm._v("Patient")]), _c("br"), _vm.loading ? _c("b-skeleton") : _c("span", [_vm._v(_vm._s(_vm.rowData.firstname) + " " + _vm._s(_vm.rowData.lastname))])], 1), _c("b-col", {
    attrs: {
      cols: "3"
    }
  }, [_c("span", {
    staticClass: "text-primary text-small"
  }, [_vm._v("Doctor")]), _c("br"), _vm.loading ? _c("b-skeleton") : _c("span", [_vm._v("Dr. " + _vm._s(_vm.rowData.doctorname))])], 1), _c("b-col", {
    attrs: {
      cols: "3"
    }
  }, [_c("span", {
    staticClass: "text-primary text-small"
  }, [_vm._v("Pharmacy")]), _c("br"), _vm.loading ? _c("b-skeleton") : _vm._e(), _vm.patientData.pharmacy && !_vm.loading ? _c("span", [_vm._v(" " + _vm._s(_vm.patientData.pharmacy.name) + " ")]) : _vm._e()], 1)], 1), _c("b-row", [_c("b-col", [_c("span", {
    staticClass: "text-primary text-small"
  }, [_vm._v("Subscription")])])], 1), _c("b-row", {
    staticClass: "mt-4"
  }, [_c("b-col", {
    attrs: {
      cols: "4"
    }
  }, [_c("span", {
    staticClass: "text-muted text-small"
  }, [_vm._v("Prescription")])]), _c("b-col", {
    attrs: {
      cols: "2"
    }
  }, [_c("span", {
    staticClass: "text-muted text-small"
  }, [_vm._v("Refills")])]), _c("b-col", {
    attrs: {
      cols: "3"
    }
  }, [_c("span", {
    staticClass: "text-muted text-small"
  }, [_vm._v("Last Payment")])]), _c("b-col", {
    attrs: {
      cols: "1"
    }
  }, [_c("span", {
    staticClass: "text-muted text-small"
  }, [_vm._v("Amount")])]), _c("b-col", {
    attrs: {
      cols: "1"
    }
  }, [_c("span", {
    staticClass: "text-muted text-small"
  }, [_vm._v("Cancel")])]), _c("b-col", {
    attrs: {
      cols: "1"
    }
  }, [_c("span", {
    staticClass: "text-muted text-small"
  }, [_vm._v("Refund")])])], 1), _vm.loading ? _c("b-row", [_c("b-col", {
    attrs: {
      cols: "4"
    }
  }, [_c("b-skeleton", {
    attrs: {
      width: "80%"
    }
  })], 1), _c("b-col", {
    attrs: {
      cols: "2"
    }
  }, [_c("b-skeleton", {
    attrs: {
      width: "80%"
    }
  })], 1), _c("b-col", {
    attrs: {
      cols: "3"
    }
  }, [_c("b-skeleton", {
    attrs: {
      width: "80%"
    }
  })], 1), _c("b-col", {
    attrs: {
      cols: "1"
    }
  }, [_c("b-skeleton", {
    attrs: {
      width: "80%"
    }
  })], 1), _c("b-col", {
    attrs: {
      cols: "1"
    }
  }, [_c("b-skeleton", {
    attrs: {
      width: "80%"
    }
  })], 1), _c("b-col", {
    attrs: {
      cols: "1"
    }
  }, [_c("b-skeleton", {
    attrs: {
      width: "80%"
    }
  })], 1)], 1) : _vm._e(), _vm._l(_vm.prescriptionData, function (prescription) {
    return _c("b-row", {
      key: prescription.id,
      staticClass: "mt-2"
    }, [_c("b-col", {
      attrs: {
        cols: "4"
      }
    }, [_vm._v(" " + _vm._s(prescription.medicinename) + " "), _c("span", {
      staticClass: "text-muted"
    }, [_vm._v("| " + _vm._s(prescription.dosage))])]), _c("b-col", {
      attrs: {
        cols: "2"
      }
    }, [_vm._v(" " + _vm._s(_vm.getRefillString(prescription)) + " ")]), _c("b-col", {
      attrs: {
        cols: "3"
      }
    }, [_vm._v(" " + _vm._s(_vm.getPaymentString(prescription.lastpaymentmade)) + " ")]), _c("b-col", {
      attrs: {
        cols: "1"
      }
    }, [_vm._v(" $" + _vm._s(prescription.refundamount) + " "), prescription.refundamount == 0 ? _c("span", [_vm._v("(Non-refundable)")]) : _vm._e()]), _c("b-col", {
      staticClass: "text-center",
      attrs: {
        cols: "1"
      }
    }, [_c("b-form-checkbox", {
      attrs: {
        disabled: _vm.processing,
        value: prescription.id,
        "unchecked-value": null
      },
      model: {
        value: _vm.prescriptionToCancel,
        callback: function ($$v) {
          _vm.prescriptionToCancel = $$v;
        },
        expression: "prescriptionToCancel"
      }
    })], 1), _c("b-col", {
      staticClass: "text-center",
      attrs: {
        cols: "1"
      }
    }, [_c("b-form-checkbox", {
      attrs: {
        disabled: !_vm.prescriptionToCancel.includes(prescription.id) || _vm.processing || !prescription.refundable,
        value: prescription.id,
        "unchecked-value": null
      },
      model: {
        value: _vm.prescriptionToRefund,
        callback: function ($$v) {
          _vm.prescriptionToRefund = $$v;
        },
        expression: "prescriptionToRefund"
      }
    })], 1)], 1);
  }), _c("hr"), _c("b-row", [_c("b-col", {
    attrs: {
      offset: "6"
    }
  }, [_vm._v(" Grand Total ")]), _c("b-col", {
    attrs: {
      cols: "1"
    }
  }, [_c("b-form-input", {
    attrs: {
      readonly: ""
    },
    model: {
      value: _vm.totalString,
      callback: function ($$v) {
        _vm.totalString = $$v;
      },
      expression: "totalString"
    }
  })], 1)], 1), _c("b-row", [_c("b-col", {
    attrs: {
      cols: "6"
    }
  }, [_c("span", {
    staticClass: "text-primary text-small"
  }, [_vm._v("Reason")]), _c("b-form-select", {
    attrs: {
      options: _vm.reasons,
      disabled: _vm.loading || _vm.processing
    },
    model: {
      value: _vm.cancellationReason,
      callback: function ($$v) {
        _vm.cancellationReason = $$v;
      },
      expression: "cancellationReason"
    }
  })], 1)], 1), _c("b-row", {
    staticClass: "mt-3"
  }, [_c("b-col", [_c("span", {
    staticClass: "text-primary text-small"
  }, [_vm._v("Notes")]), _c("b-form-textarea", {
    attrs: {
      placeholder: "Notes",
      disabled: _vm.loading || _vm.processing,
      rows: "6"
    },
    model: {
      value: _vm.cancellationNotes,
      callback: function ($$v) {
        _vm.cancellationNotes = $$v;
      },
      expression: "cancellationNotes"
    }
  })], 1)], 1), _c("b-row", {
    staticClass: "my-4"
  }, [_c("b-col", {
    attrs: {
      cols: "3"
    }
  }, [_c("b-button", {
    attrs: {
      block: "",
      disabled: _vm.loading || _vm.processing,
      variant: "outline-primary",
      pill: ""
    },
    on: {
      click: function ($event) {
        _vm.showDialog = false;
      }
    }
  }, [_vm._v(" Nevermind ")])], 1), _c("b-col", {
    attrs: {
      cols: "3"
    }
  }, [_c("b-button", {
    attrs: {
      block: "",
      variant: "primary",
      pill: "",
      disabled: _vm.loading || _vm.processing || _vm.filteredPrescriptionData.length == 0
    },
    on: {
      click: _vm.cancelPrescriptions
    }
  }, [_vm._v(" Cancel ")])], 1)], 1)], 2)], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };